import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpUrlEncodingCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppInjector } from 'src/app/app-injector';
import { HttpEncoder } from 'src/app/connection/http-encoder';
import { LoggerService } from '../services/logger-service';

/**
 * HTTP通信クラスの基底クラス
 */
export abstract class HttpBase {
  /**
  * HTTP通信処理
  */
  protected httpClient: HttpClient;

  /**
  * ログ出力サービス
  */
  protected logger: LoggerService;

  /**
   * 初期化処理
   */
  public constructor() {
    this.httpClient = AppInjector.get(HttpClient);
    this.logger = new LoggerService();
  }

  /**
   * GETメソッド
   * @param uri リクエストURI
   * @param options.params 要求パラメータ
   * @returns HTTPレスポンス
   */
  public abstract get(uri: string, options: { headers: HttpHeaders, params?: any }): Observable<HttpResponse<string>>;

  /**
  * POSTメソッド
  * @param uri リクエストURI
  * @param body リクエストBODY
  * @param options.params 要求パラメータ
  * @returns HTTPレスポンス
  */
  public abstract post(uri: string, body: any, options: { headers: HttpHeaders, params?: any }): Observable<HttpResponse<string>>;

  /**
   * クエリパラメータ取得
   * @param params 要求パラメータ(jsonオブジェクト)
   * @returns クエリパラメータ
   */
  protected getQueryParams(params: any): HttpParams {
    if (params) {
      return new HttpParams({
        encoder: new HttpEncoder(),
        fromObject: params
      });
    }

    return undefined;
  }
}
