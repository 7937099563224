/**
 * HTTP StatusCodeの列挙型
 */
export enum HttpStatus {
  /** リクエストのパラメータが不正 */
  BadRequest = 400,
  /** 認証失敗(JWTが不正) */
  Unauthorized = 401,
  /** 権限なし */
  Forbidden = 403,
  /** 対象が存在しない */
  NotFound = 404,
  /** ID重複 */
  Conflict = 409,
  /** サーバー内部エラー */
  InternalServerError = 500
}
