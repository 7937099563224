import * as log from 'loglevel';
import * as prefix from 'loglevel-plugin-prefix';
import { environment } from 'src/environments/environment';

/**
 * ログ出力サービス
 */
export class LoggerService {
  /** ログ出力日時 */
  private date: Date;

  /**
   * コンストラクタ
   */
   constructor() {
    /** ログレベル定義（コンソールログ） */
    log.setLevel(environment.consoleLogLevel);
    this.date = new Date();
    prefix.reg(log);
    // ログフォーマット指定「yyyy/MM/dd hh:mm:ss.fff LogLevel:」
    prefix.apply(log, {
      template: '[%t] %l:',
      levelFormatter(level): string {
        return level.toUpperCase();
      },
      timestampFormatter(date): string {
        return `${date.toLocaleString()}.${date.getMilliseconds()}`;
      }
    });
  }

  /**
   * traceログ出力
   * @param message メッセージ
   */
  public trace(message: string): void {
    if (log.getLevel() <= log.levels.TRACE) {
      log.trace(message);
    }
  }

  /**
   * debugログ出力
   * @param message メッセージ
   */
  public debug(message: string): void {
    if (log.getLevel() <= log.levels.DEBUG) {
      log.debug(message);
    }
  }

  /**
   * infoログ出力
   * @param message メッセージ
   */
  public info(message: string): void {
    if (log.getLevel() <= log.levels.INFO) {
      log.info(message);
    }
  }

  /**
   * warmログ出力
   * @param message メッセージ
   */
  public warn(message: string): void {
    if (log.getLevel() <= log.levels.WARN) {
      log.warn(message);
    }
  }

  /**
   * errorログ出力
   * @param message メッセージ
   */
  public error(message: string): void {
    if (log.getLevel() <= log.levels.ERROR) {
      log.error(message);
    }
  }
}
