import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { EventCondition } from 'src/app/models/notification';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})

  /**
 * お知らせ一覧画面のフィルタ機能に使用する部品
 */
export class FilterComponent implements OnInit {

  public handlingList: string[] =[
    'ALL', '処理済', '未処理'
  ];

  // フィルタonoff
  public filterflag: boolean;

  @Input() devices: string[] = [];

  public get deviceIdList(): string[] {
    return ['ALL'].concat(this.devices);
  }

  private selectedDeviceId: string;

  /** お知らせ検索条件 */
  @Input() condition: EventCondition = new EventCondition([], null, null, {});
    
  public get occuredStart() : string {
    if (this.condition.occurredStart === null) {
      return '';
    }
    return this.dateToInputStr(this.condition.occurredStart);
  }

  public set occuredStart(v : string) {
    this.condition.occurredStart = v === '' ? null : new Date(v);
  }
  
  public get occuredEnd() : string {
    if (this.condition.occurredEnd === null) {
      return null;
    }
    return this.dateToInputStr(this.condition.occurredEnd);
  }

  public set occuredEnd(v : string) {
    this.condition.occurredEnd = v === '' ? null : new Date(v);
  }

  public get deviceId() : string {
    return this.selectedDeviceId ?? 'ALL';
  }

  public set deviceId(v : string) {
    this.selectedDeviceId = v;

    if (v === 'ALL') {
      this.condition.deviceIdList = this.devices;
    }
    else if (this.devices.includes(v)) {
      this.condition.deviceIdList = [v];
    }

    console.log(this.condition.deviceIdList)
  }

  public get handled() : string {
    const value = this.condition.statuses['handled'];
    return value === 1 ? '処理済' :
           value === 0 ? '未処理' :
           'ALL';    
  }

  public set handled(text : string) {
    const value = text === '処理済' ? 1 :
                  text === '未処理' ? 0 :
                  null;

    if (value === null) {
      delete this.condition.statuses['handled'];
      console.log(this.condition.statuses);
      return;
    }

    this.condition.statuses['handled'] = value;
    console.log(this.condition.statuses);
  }

  /** 
   * 親コンポーネントに引き渡す検索ボタンクリックイベント 
  */
  @Output() clickSearchEvent = new EventEmitter<EventCondition>(); 

  constructor() {
    this.filterflag = true;
  }

  ngOnInit() {
  }

  /**
   * フィルタ切り替えボタンクリックイベント
   */
  changeFilter() {
    if (this.filterflag == true) {
      this.filterflag = false;
    }
    else {
      this.filterflag = true;
    }
  }

  /** 
   * デバイス選択クリックイベント
   */
  onClickSelectDevice() {
    window.alert();
    // this.clickDeviceEvent.emit(this.device);
  }

  /**
   * 検索ボタンクリックイベント
   */
  onClickSearch() {
    this.clickSearchEvent.emit(this.condition);
  }

  private dateToInputStr(d: Date) {
    const pad = (n: number, len: number) => `${Array(len).join('0')}${n}`.slice(-len);

    const year = pad(d.getFullYear(), 4);
    const month = pad(d.getMonth() + 1, 2);
    const day = pad(d.getDate(), 2);
    const hour = pad(d.getHours(), 2);
    const min = pad(d.getMinutes(), 2);
    const sec = pad(d.getSeconds(), 2);

    // yyyy-MM-ddThh:mm:ss
    return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
  }

}
