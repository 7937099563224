import { Datatype } from "./datatype";

/**
 * データタイプツリーのノード
 */
export class DatatypeNode {
  /**
   * コンストラクタ
   * @param type データタイプ
   * @param children 子ノードのリスト
   */
  constructor(public readonly type: Datatype,
    public readonly children: Array<DatatypeNode>) {
  }
}
