import { User } from '../models/user';
import { ICloud } from '../services/icloud';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/services/logger-service';
import { EventService } from './event-service';
import { tap } from 'rxjs/operators';

/**
 * ログインサービス
 */
export class LoginService {

  /** ログサービス */
  private readonly logger: LoggerService;
  
  /** クラウド通信クラス */
  private readonly cloud: ICloud;

  private readonly eventService: EventService;

  /**
   * コンストラクタ
   * @param logger ログサービス
   * @param cloud クラウド通信クラス
   */
  constructor(logger: LoggerService, cloud: ICloud, eventService: EventService){
    this.logger = logger;
    this.cloud = cloud;
    this.eventService = eventService;
  }

  /**
   * ログイン
   * @param user ユーザー情報
   * @returns ログイン認証結果
   */
  public login(user: User): Observable<boolean> {
    this.logger.debug("[LoginService] login");
    return this.cloud.login(user).pipe(
      tap(ok => {
        if (ok) {
          this.eventService.startUnreadNotificationCounter();
        }
      })
    );
  }

  /**
   * ログアウト
   */
  public logout(): void {
    this.logger.debug("[LoginService] logout");
    this.cloud.logout();
    this.eventService.stopUnreadNotificationCounter();
  }
}