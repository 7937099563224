import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * HttpParamクラスでエンコードする際に使用する
 */
export class HttpEncoder extends HttpUrlEncodingCodec {
  /**
   * HttpParam.valueをエンコード
   * @param value HttpParam.value
   * @returns エンコード文字列
   */
  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}
