import { DatatypeNode } from "./datatype-node";
import { TypedDatatype } from "./datatype";
import { DatatypeTree } from "./datatype-tree";

// 型付け先のクラスを定義
// 検証のためにデフォルト値で実体化する必要があるためinterfaceではなくclassにした

/**
 * デバイス
 */
export class TDevice {
}

/**
 * 検温センサー
 */
export class TThermoSensor extends TDevice {
  /**
   * 温度
   */
  temperature: number = 0;
}

// データタイプツリーを定義する

/**
 * 検温センサーデータタイプ
 */
export const ThermoSensor = new TypedDatatype("ThermoSensor", TThermoSensor);

/**
 * デバイスデータタイプ
 */
export const Device = new TypedDatatype("Device", TDevice);

/**
 * ルートノード
 * 
 * ここでデータタイプの親子関係を定義する。
 */
export const Root = new DatatypeNode(Device, [
  new DatatypeNode(ThermoSensor, [])
]);

/**
 * データタイプツリー
 */
export const Tree = new DatatypeTree(Root);
