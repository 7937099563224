/**
 * メッセージ定義クラス
 */
export const MessageConst = {
  confirmLogout: "ログアウトしますか？",
  notFoundDevice: "デバイスが見つかりません。",
  requiredUserId: "ユーザーIDを入力してください。",
  requiredPassword: "パスワードを入力してください。",
  noMatchUserId: "ユーザーIDは半角英数字で入力してください。",
  noMatchPassword: "パスワードは半角英数字で入力してください。",
  failedLogin: "ログインに失敗しました。",
  incorrectUserInfo: "ユーザーIDまたはパスワードが違います。",
  failedAuth: "認証に失敗しました。再度ログインしてください。",
  failedDeviceList: "デバイス一覧の取得に失敗しました。",
  failedDeviceData: "デバイスデータの取得に失敗しました。",
  failedDeviceSpec: "デバイス詳細情報の取得に失敗しました。",
  failedDeviceGraph: "デバイスデータグラフの取得に失敗しました。",
  failedNotificationList: "お知らせ一覧の取得に失敗しました。",
  invalidDateRange: "開始日付には終了日付よりも過去の日付を入力してください。",
  failedNotificationDetail: "お知らせ詳細の取得に失敗しました。",
  failedUpdateNotificationDetail: "お知らせ詳細の更新に失敗しました。",
  timeout: "接続がタイムアウトしました。",
  loading: "処理中です。<br>しばらくお待ちください。",
  name: "名前",
  deviceId: "デバイスID",
  setupDate: "設置日",
  location: "設置場所",
  updatePeriod: "更新頻度(秒)",
  lifeTime: "データの保存期間(日)",

  // notice-list用
  noticeDate: "通知日時",
  status: "ステータス",
  subject: "件名",
  comment: "コメント"


}
