import { ICloud } from '../services/icloud';
import { EMPTY, merge, Observable, of, Subject, timer } from 'rxjs';
import { LoggerService } from 'src/app/services/logger-service';
import { catchError, delay, map, mergeMap, retryWhen, switchAll, tap } from 'rxjs/operators';
import { EventCondition, INotification } from '../models/notification';
import { ConnectionError } from '../connection/connection-error.enum';

/**
 * イベントサービス
 */
export class EventService {

  /** ログサービス */
  private logger: LoggerService;

  /** クラウド通信クラス */
  private readonly cloud: ICloud;

  private readonly eventDetailReceived: Subject<boolean> = new Subject<boolean>();

  private readonly startStop: Subject<boolean> = new Subject<boolean>();

  /**
   * コンストラクタ
   * @param logger ログサービス
   * @param cloud クラウド通信クラス
   */
  constructor(logger: LoggerService, cloud: ICloud) {
    this.logger = logger;
    this.cloud = cloud;
  }

  /**
   * getNotificationList
   */
  public getNotificationList(condition: EventCondition): Observable<INotification[]> {
    this.logger.info(`お知らせ一覧取得 condition=${condition}`);

    return this.cloud.getNotificationList(condition).pipe(
      tap(notifications => this.logger.info(`お知らせ一覧取得結果 notifications=${notifications.length}`)),
      catchError(e => {
        if (e === ConnectionError.NotFound) {
          this.logger.info(`お知らせ一覧取得結果 合致なし`);
          return of([]);
        }

        this.logger.error(`お知らせ一覧取得失敗 error=${e}`)
        throw e
      })
    );
  }

  /**
   * getNotificationCount
   */
  public getUnreadNotificationCount() {
    this.logger.info('未読お知らせカウント取得');

    const condition = new EventCondition([], null, null, { read: false });

    return this.cloud.getNotificationCount(condition).pipe(
      tap(notifications => this.logger.info(`未読お知らせカウント取得結果 notifications=${notifications}`)),
      catchError(e => {
        this.logger.error(`未読お知らせカウント取得失敗 error=${e}`)
        throw e
      })
    );
  }

  /**
   * getUnreadNotificationCounter
   */
  public getUnreadNotificationCounter(period: number): Observable<number> {
    const activate = () => {
      const elapsed = timer(0, period);
      const detailReceived = this.eventDetailReceived.asObservable();

      return merge(elapsed, detailReceived).pipe(
        mergeMap(() => this.getUnreadNotificationCount()),
        retryWhen(error => error.pipe(
          tap(() => this.logger.info('未読カウンターリトライ発生')),
          delay(period)) // エラー発生した場合は指定時間後リトライ
        )
      );
    };

    const counterSwitched = this.startStop.asObservable();
    return counterSwitched.pipe(
      tap(b => this.logger.info(`未読数カウンター active=${b}`)),
      map(b => b ? activate() : EMPTY),
      switchAll()
    );
  }

  public startUnreadNotificationCounter() {
    this.startStop.next(true);
  }
  
  public stopUnreadNotificationCounter() {
    this.startStop.next(false);
  }

  /**
   * getNotificationDetail
   */
  public getNotificationDetail(eventIdentifier: string): Observable<INotification> {
    this.logger.info('お知らせ詳細取得');

    return this.cloud.getNotificationDetail(eventIdentifier).pipe(
      tap(notification => {
        this.logger.info(`お知らせ詳細取得結果 notification=${notification}`);

        // イベント詳細を取得成功したので、未読カウンターに通知
        this.eventDetailReceived.next(true);
      }),
      catchError(e => {
        this.logger.error(`お知らせ詳細取得失敗 error=${e}`)
        throw e
      })
    );
  }

  /**
   * updateNotificationDetail
   */
  public updateNotificationDetail(notification: INotification): Observable<boolean> {
    this.logger.info('お知らせ詳細取得');

    return this.cloud.postNotificationDetail(notification).pipe(
      tap(result => this.logger.info(`お知らせ詳細取得結果 result=${result}`)),
      catchError(e => {
        this.logger.error(`お知らせ詳細取得失敗 error=${e}`)
        throw e
      })
    );
  }
}
