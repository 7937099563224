import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { HttpBase } from './http-base';
import { map, tap, timeout } from 'rxjs/operators';
import { Const } from 'src/app/connection/const';

/**
 * HTTP通信クラス
 */
export class Http extends HttpBase {
  /**
   * コンストラクタ
   */
  public constructor() {
    super();
  }

  /**
   * GETメソッド
   * @param uri リクエストURI
   * @param options 要求パラメータ
   * @returns HTTPレスポンス
   */
  public get(uri: string, options: { headers: HttpHeaders, params?: any }): Observable<HttpResponse<string>> {
    this.logger.info(`[Http] get start uri=${uri}, options=${JSON.stringify(options)}`);
    const subject: Subject<any> = new Subject<any>();
    const subscription = this.httpClient.get(uri, {
      headers: options.headers,
      responseType: 'text',
      observe: 'response',
      params: this.getQueryParams(options.params)
    }).pipe(
      timeout(Const.HttpOptions.timeout),
      tap(res => this.logger.info(`[Http] get success!! response=${JSON.stringify(res)}`)),
      map(res => JSON.parse(res.body)) // parseでエラー発生する可能性があるため、pipe内で実行
    ).subscribe(json => {
      subject.next(json);
      subscription.unsubscribe();
    }, error => {
      this.logger.error(`[Http] get error!! response=${JSON.stringify(error)}`);
      subject.error(error);
      subscription.unsubscribe();
    });

    return subject.asObservable();
  }

  /**
   * POSTメソッド
   * @param uri リクエストURI
   * @param body リクエストBODY
   * @param options 要求パラメータ
   * @returns HTTPレスポンス
   */
  public post(uri: string, body: any, options: { headers: HttpHeaders, params?: any }): Observable<HttpResponse<string>> {
    this.logger.info(`[Http] post start uri=${uri}, body=${JSON.stringify(body)}, options=${JSON.stringify(options)}`);
    const subject: Subject<any> = new Subject<any>();
    const subscription = this.httpClient.post(uri, body, {
      headers: options.headers,
      responseType: 'text',
      observe: 'response',
      params: this.getQueryParams(options.params)
    }).pipe(
      timeout(Const.HttpOptions.timeout),
      tap(res => this.logger.info(`[Http] post success!! response=${JSON.stringify(res)}`)),
      map(res => JSON.parse(res.body)) // parseでエラー発生する可能性があるため、pipe内で実行
    ).subscribe(json => {
      subject.next(json);
      subscription.unsubscribe();
    }, error => {
      this.logger.error(`[Http] post error!! response=${JSON.stringify(error)}`);
      subject.error(error);
      subscription.unsubscribe();
    });

    return subject.asObservable();
  }
}
