import { TDevice } from "./datatype-consts";

/**
 * データタイプ
 */
export class Datatype {
  /**
   * ひな型オブジェクト
   * 
   * 型付けの際にObject.keysを使って項目が足りているかチェックするため実体化されたオブジェクトが必要。
   */
  readonly template: any;

  /**
   * コンストラクタ
   * 
   * この宣言「{ new(): any }」でコンストラクタを受け取れる。
   * 下記のように呼び出し元は型を引数に渡す。
   * new TypedDatatype("ThermoSensor", TThermoSensor)
   * 
   * @param name データタイプ名
   * @param ctor ひな型オブジェクトのコンストラクタ
   */
  constructor(public readonly name: string, ctor: { new(): any }) {
    this.template = new ctor();
  }

  /**
   * デバイスデータの検証
   * 
   * データタイプに必要な項目がデバイスデータに存在しているか検証する。
   * 
   * @param deviceData デバイスデータ
   * @returns 検証結果
   */
  validateData(deviceData: any): boolean {
    const typeKeys = Object.keys(this.template);
    return typeKeys.every(k => k in deviceData);
  }
}

/**
 * 型付きデータタイプ
 */
export class TypedDatatype<T extends TDevice> extends Datatype {
  /**
   * 型付きのひな型オブジェクト
   * 
   * Datatypeで持っているanyのデータタイプでも問題ないと思うが、型付きのものも保持しておく。
   */
  private typedTemplate: T;

  /**
   * コンストラクタ
   * @param name データタイプ名
   * @param ctor ひな型オブジェクトのコンストラクタ
   */
  constructor(public readonly name: string, ctor: { new(): T }) {
    super(name, ctor);
    this.typedTemplate = new ctor();
  }

  /**
   * デバイスデータの検証
   * 
   * データタイプに必要な項目がデバイスデータに存在しているか、及び型が一致しているか検証する。
   * 
   * @param deviceData デバイスデータ
   * @returns 検証結果
   */
  validateData(deviceData: any): boolean {
    const typeKeys = Object.keys(this.typedTemplate);
    return typeKeys.every(k => typeof this.typedTemplate[k] === typeof deviceData[k]);
  }
}

/**
 * デバイスデータのユーザー定義タイプガード
 * 
 * 下記のようにデバイスデータの可変部を期待するデータタイプに型付けする。
 * const actual = Tree.Dispatch("ThermoSensor", デバイスデータ);
 * if (validateData(デバイスデータ, actual, ThermoSensor)) {
 *  デバイスデータ.Temperture // タイプガードが効き、ここでは変換後のオブジェクトを使える
 * }
 * 
 * @param deviceData デバイスデータ
 * @param actual データタイプの探索結果
 * @param expected 期待するデータタイプ
 * @returns 検証結果（タイプガード）
 */
export function validateData<T extends TDevice>(deviceData: any,
  actual: Datatype,
  expected: TypedDatatype<T>): deviceData is T {
  return actual.name === expected.name && expected.validateData(deviceData);
}
