import { TimeoutError } from "rxjs";
import { DeviceData } from "../models/device-data";
import { DeviceList } from "../models/device-list";
import { DeviceSpec } from "../models/device-spec";
import { EventCondition, INotification } from "../models/notification";
import { CognitoError } from "./cognito-error.enum";
import { ConnectionError } from "./connection-error.enum";
import { HttpStatus } from "./http-status.enum";

export class Converter {
  /**
   * HTTPレスポンス情報をデバイス一覧に変換する
   * @param res HTTPレスポンス情報
   * @returns デバイス一覧
   */
  convertDeviceList(res: any): DeviceList {
    let result = new DeviceList();
    // 必須項目がなければ、型変換できない為、空をセットする
    result.devices = Array.isArray(res.devices) ? res.devices : [];

    // デバイスIDで昇順ソート
    result.devices = result.devices.sort((a, b) => {
      if (a.deviceId < b.deviceId) {
        return -1;
      }
      if (a.deviceId > b.deviceId) {
        return 1;
      }
      return 0;
    });
    
    return result;
  }

  /**
   * HTTPレスポンス情報をデバイスデータに変換する
   * @param res HTTPレスポンス
   * @returns デバイスデータ
   */
  convertDeviceData(res: any): DeviceData {
    let result = new DeviceData();
    // 必須項目がなければ、型変換できない為、空をセットする
    result.deviceId = res.deviceId;
    result.datatype = res.datatype;
    result.deviceData = res.deviceData ? res.deviceData : {};
    result.version = res.version;
    return result;
  }

  /**
   * HTTPレスポンス情報をデバイス詳細情報に変換する
   * @param res HTTPレスポンス
   * @returns デバイス詳細情報
   */
  convertDeviceSpec(res: any): DeviceSpec {
    let result = new DeviceSpec();
    // 必須項目がなければ、型変換できない為、空をセットする
    result.deviceId = res.deviceId;
    result.datatype = res.datatype;
    result.deviceName = res.deviceName;
    result.specs = res.specs ? res.specs : {};
    result.version = res.version;

    return result;
  }

  convertDeviceDataGraph(res: any): string {
    return res.graphUrl
  }

  convertNotificationList(res: any): INotification[] {
    if ('events' in res) {
      const events = res.events;
      if (Array.isArray(events)) {
        return events.map(this.convertNotification)
      }
  
      return [];
    }

    throw new Error('convertNotificationList 必須項目なし')
  }

  convertNotification(res: any): INotification {
    if ('eventType' in res &&
        'eventIdentifier' in res &&
        'deviceID' in res &&
        'occurred' in res &&
        'statuses' in res) {
      return res as INotification;
    }

    throw new Error('convertNotification 必須項目なし')
  }

  convertNotificationCount(res: any): number {
    if ('count' in res) {
      return res.count as number;
    }

    throw new Error('convertNotificationCount 必須項目なし')
  }

  public convertEventConditionToReq(condition: EventCondition): {[key: string]: any} {
    let req: {[key: string]: any} = {};
    if (condition.deviceIdList !== null && condition.deviceIdList.length > 0) {
      req['deviceIdList'] = condition.deviceIdList;
    }

    const pad = (n: number, len: number) => (Array(len).join('0') + n).slice(-len);
    const format = (d: Date) => {
      const year = pad(d.getFullYear(), 4);
      const month = pad(d.getMonth() + 1, 2);
      const day = pad(d.getDate(), 2);
      const hour = pad(d.getHours(), 2);
      const min = pad(d.getMinutes(), 2);
      const sec = pad(d.getSeconds(), 2);
      return `${year}/${month}/${day} ${hour}:${min}:${sec}`;
    };
    if (condition.occurredStart !== null) {
      req['occurredStart'] = format(condition.occurredStart);
    }
    if (condition.occurredEnd !== null) {
      req['occurredEnd'] = format(condition.occurredEnd);
    }

    if (condition.statuses !== null) {
      req['statuses'] = condition.statuses;
    }

    return req
  }

  public convertNotificationToReq(notification: INotification): {[key: string]: any} {
    return notification.statuses;
  }

  /**
   * HTTP通信のエラー情報をエラーコードに変換する
   * @param error エラー情報
   * @returns エラーコード
   */
  convertHttpError(error: any): ConnectionError {
    let errorCode: ConnectionError;
    if (error.status === HttpStatus.Unauthorized
      || error.status === HttpStatus.Forbidden) {
      // 認証失敗 or 権限無しの場合
      errorCode = ConnectionError.Unauthorized;
    } else if (error.status === HttpStatus.NotFound) {
      // デバイスが存在しない場合
      errorCode = ConnectionError.NotFound;
    } else if (error instanceof TimeoutError) {
      // タイムアウトの場合
      errorCode = ConnectionError.Timeout;
    } else {
      // 上記以外の場合
      errorCode = ConnectionError.Other;
    }

    return errorCode;
  }

  /**
   * AWS Cognitoエラー情報をクラウド通信のエラーコードに変換する
   * @param error Cognitoエラー情報
   * @returns クラウド通信のエラーコード
   */
  convertCognitoError(error: any): ConnectionError {
    let errorCode: ConnectionError;
    if (error.code === CognitoError.NotAuthorizedException
      || error.code === CognitoError.UserNotFoundException) {
      // ユーザー または、パスワードの入力間違いの場合
      errorCode = ConnectionError.NotFound;
    } else {
      // 上記以外の場合
      errorCode = ConnectionError.Other;
    }

    return errorCode;
  }
}
