import { IView } from '../../services/iview';
import { AlertController, LoadingController, MenuController, NavController } from '@ionic/angular';
import { AppInjector } from 'src/app/app-injector';
import { AppProviderService } from 'src/app/app-provider.service';
import { LoggerService } from 'src/app/services/logger-service';
import { LoginService } from 'src/app/services/login-service';
import { DeviceService } from 'src/app/services/device-service';
import { MessageConst } from 'src/app/gui/pages/message-const';
import { DialogType } from 'src/app/gui/pages/dialog-type.enum';
import { ConnectionError } from 'src/app/connection/connection-error.enum';
import { ConnectionErrorList } from 'src/app/models/connection-error-list';
import { EventService } from 'src/app/services/event-service';

/**
 * 各画面(Pages)の基底クラス
 * 画面(Page)の作成時は、本クラスを継承する
 */
export class BasePage implements IView {
  /** アラートコントローラ */
  protected alertController: AlertController;

  /** メニューコントローラ */
  protected menuController: MenuController;

  /** ナビゲーションコントローラ */
  protected navController: NavController;

  /** ローディングコントローラ */
  protected loadingController: LoadingController;

  /** ログサービス */
  protected logger: LoggerService;

  /** ログインサービス */
  protected loginService: LoginService;

  /** デバイスサービス */
  protected deviceService: DeviceService;

  protected eventService: EventService;
  
  /** 
   * 通信エラー一覧
   * クラウドとの通信失敗時に使用する
   * 通信エラーに紐づくダイアログ表示メッセージを設定する
   * Key: 通信エラー
   * Value: [ダイアログ表示タイプ, メッセージ内容]
   */
  private readonly _connectionErrorList: ConnectionErrorList = {
    // 認証失敗エラーメッセージ
    [ConnectionError.Unauthorized]: [DialogType.Error, MessageConst.failedAuth],
    // 通信タイムアウトエラーメッセージ
    [ConnectionError.Timeout]: [DialogType.Error, MessageConst.timeout],
  };

  /**
   * 通信エラー一覧のCloneを返却
   */
  get connectionErrorList(): ConnectionErrorList {
    return JSON.parse(JSON.stringify(this._connectionErrorList));
  }

  /**
   * コンストラクタ
   */
  public constructor() {
    // 各APIのセット
    this.alertController = AppInjector.get(AlertController);
    this.menuController = AppInjector.get(MenuController);
    this.navController = AppInjector.get(NavController);
    this.loadingController = AppInjector.get(LoadingController);

    // 各サービスのセット
    const provider = AppInjector.get(AppProviderService);
    this.logger = provider.logger;
    this.loginService = provider.loginService;
    this.deviceService = provider.deviceService;
    this.eventService = provider.eventService;
  }

  /**
   * ローディング画面を表示する
   * @returns ローディング画面のPromise
   */
  public async presentLoading(): Promise<void> {
    const loading = await this.loadingController.create({
      message: MessageConst.loading,
    });
    return loading.present();
  }

  /**
   * ローディング画面を閉じる
   * 
   * ダイアログが表示されていない場合もエラーなしで実行可能。
   */
  public async dismissLoading(): Promise<void> {
    // ローディングが表示されているかどうかに関わらず閉じる
    const loading = await this.loadingController.getTop();
    await loading?.dismiss();
  }

  /**
   * 確認ダイアログを表示する
   * @param msg 確認メッセージ
   * @returns true:はい、false:いいえ
   */
  public async showConfirmDialog(msg: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => (resolveFunction = resolve));
    const alert = await this.alertController.create({
      header: '確認',
      message: msg,
      buttons: [
        {
          text: 'いいえ',
          role: 'cancel',
          handler: () => resolveFunction(false),
        },
        {
          text: 'はい',
          cssClass: 'alertButton',
          handler: () => resolveFunction(true),
        }
      ]
    });

    await alert.present();
    return promise;
  }

  /**
   * 警告ダイアログを表示する
   * @param msg 警告メッセージ
   */
  public async showWarnDialog(msg: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => (resolveFunction = resolve));
    const alert = await this.alertController.create({
      header: '警告',
      message: msg,
      buttons: [
        {
          text: 'OK',
          handler: () => resolveFunction(true)
        }
      ]
    });

    await alert.present();
    return promise;
  }

  /**
   * エラーダイアログを表示する
   * @param msg エラーメッセージ
   */
  public async showErrorDialog(msg: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => (resolveFunction = resolve));
    const alert = await this.alertController.create({
      header: 'エラー',
      message: msg,
      buttons: [
        {
          text: 'OK',
          handler: () => resolveFunction(true)
        }
      ]
    });

    await alert.present();
    return promise;
  }

  /**
   * メッセージダイアログを表示する
   * @param connectionErrorMessages [ダイアログ表示タイプ(確認、警告、エラー)、表示メッセージ]
   */
  public async showDialog(errorInfo: [DialogType, string]): Promise<boolean> {
    const [dialogType, showMessage] = errorInfo;
    switch (dialogType) {
      case DialogType.Confirm:
        return await this.showConfirmDialog(showMessage);
      case DialogType.Warn:
        await this.showWarnDialog(showMessage);
        return true;
      case DialogType.Error:
        await this.showErrorDialog(showMessage);
        return true;
    }
  }

  /**
   * 表示中のダイアログを閉じる
   * 
   * ダイアログが表示されていない場合もエラーなしで実行可能。
   * ダイアログの各ボタンに割り当てたハンドラは起動しないため、Promiseは完了しなくなることに注意。
   */
  public async cancelDialog() {
    // 温度表示画面でダイアログが重ねて表示されるためすべて閉じる
    let top = await this.alertController.getTop(); // ダイアログなしの場合はundefined
    while (top && await top.dismiss()) { // dismissに失敗した場合は続けても無限ループとなってしまうので終了（閉じた直後に実施された場合などが該当）
      top = await this.alertController.getTop()
    }
  }

  /**
   * ログアウト後、ログイン画面に戻る
   */
  public backLoginPage(): void {
    this.loginService.logout();
    this.navController.navigateRoot('/');
  }
}
