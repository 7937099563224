import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./gui/pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'device-list',
    loadChildren: () => import('./gui/pages/device-list/device-list.module').then( m => m.DeviceListPageModule)
  },
  {
    path: 'temperature/:deviceId/:deviceName',
    loadChildren: () => import('./gui/pages/temperature/temperature.module').then( m => m.TemperaturePageModule)
  },
  {
    path: 'device-spec/:deviceId',
    loadChildren: () => import('./gui/pages/device-spec/device-spec.module').then( m => m.DeviceSpecPageModule)
  },
  {
    path: 'graph/:deviceId',
    loadChildren: () => import('./gui/pages/graph/graph.module').then( m => m.GraphPageModule)
  },
  {
    path: 'notice-detail/:eventIdentifier',
    loadChildren: () => import('./gui/pages/notice-detail/notice-detail.module').then( m => m.NoticeDetailPageModule)
  },
  {
    path: 'notice-list',
    loadChildren: () => import('./gui/pages/notice-list/notice-list.module').then( m => m.NoticeListPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
