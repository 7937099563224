import { Observable, of, Subject, throwError } from 'rxjs';
import { ICloud } from 'src/app/services/icloud';
import { User } from 'src/app/models/user';
import { LoggerService } from 'src/app/services/logger-service'
import { DeviceList } from 'src/app/models/device-list';
import { DeviceData } from 'src/app/models/device-data';
import { DeviceSpec } from 'src/app/models/device-spec';
import { ConnectionError } from 'src/app/connection/connection-error.enum';
import { Converter } from './converter';

import { EventCondition, INotification } from 'src/app/models/notification';

/**
 * クラウド通信クラス(テスト用)
 */
export class CloudConnectionMock implements ICloud {

  /** ログ出力サービス */
  private logger: LoggerService;

  /** HTTP通信情報の変換クラス */
  private converter: Converter;

  /** クラウド通信モックの応答時間 */
  private readonly responseTime: number = 1;

  /** クラウド通信の応答情報一覧 */
  public readonly cloudResponseList = {

    /***********************************
     * エラーコード
    ************************************/
    /** 認証失敗 */
    error_Unauthorized: ConnectionError.Unauthorized,
    /** 対象が存在しない */
    error_NotFound: ConnectionError.NotFound,
    /** 接続タイムアウト */
    error_Timeout: ConnectionError.Timeout,
    /** 上記以外 */
    error_Other: ConnectionError.Other,


    /***********************************
     * login
    ************************************/
    /** 正常応答 */
    login_001: true,


    /***********************************
    * getDeviceList
    ************************************/
    /** 正常応答 */
    getDeviceList_001: {
      devices: [
        {
          "deviceId": "Sensor A",
          "datatype": "temperature",
          "deviceName": "会議室の温度",
          "version": "1.0.0"
        },
        {
          "deviceId": "Sensor B",
          "datatype": "temperature",
          "deviceName": "応接室の温度",
          "version": "1.0.0"
        }
      ]
    },
    /** 異常応答(deviceId = 空文字) */
    getDeviceList_002: {
      devices: [
        {
          "deviceId": "",
          "datatype": "datatype",
          "deviceName": "deviceName",
          "version": "version"
        }
      ]
    },
    /** 異常応答(datatype = null) */
    getDeviceList_003: {
      devices: [
        {
          "deviceId": "deviceId",
          "datatype": null,
          "deviceName": "deviceName",
          "version": "version"
        }
      ]
    },
    /** 異常応答(deviceName = undefined) */
    getDeviceList_004: {
      devices: [
        {
          "deviceId": "deviceId1",
          "datatype": "datatype1",
          "deviceName": undefined,
          "version": "version"
        }
      ]
    },
    /** 異常応答(version = undefined) */
    getDeviceList_005: {
      devices: [
        {
          "deviceId": "deviceId1",
          "datatype": "datatype1",
          "deviceName": "deviceName",
          "version": undefined
        }
      ]
    },
    /** 異常応答(devices[空]) */
    getDeviceList_006: {
      devices: []
    },
    /** 異常応答(devices = undefined) */
    getDeviceList_007: {
      devices: undefined
    },


    /***********************************
    * getDeviceData
    ************************************/
    /** 正常応答 */
    getDeviceData_001: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceData": {
        temperature: 30.5
      },
      "version": "1.0.0"
    },
    /** 異常応答(deviceId = 空文字) */
    getDeviceData_002: {
      "deviceId": "",
      "datatype": "ThermoSensor",
      "deviceData": {
        temperature: undefined
      },
      "version": "1.0.0"
    },
    /** 異常応答(datatype = null) */
    getDeviceData_003: {
      "deviceId": "Sensor A",
      "datatype": null,
      "deviceData": {
        temperature: 30
      },
      "version": "1.0.0"
    },
    /** 異常応答(deviceData = undefined) */
    getDeviceData_004: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceData": undefined,
      "version": "1.0.0"
    },
    /** 異常応答(temperature = undefined) */
    getDeviceData_005: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceData": {
        temperature: undefined
      },
      "version": "1.0.0"
    },
    /** 異常応答(deviceData = {空}) */
    getDeviceData_006: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceData": {},
      "version": "1.0.0"
    },
    /** 異常応答(version = undefined) */
    getDeviceData_007: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceData": {
        temperature: undefined
      },
      "version": undefined
    },
    /** 異常応答(datatype = dummy) */
    getDeviceData_008: {
      "deviceId": "Sensor A",
      "datatype": "dummy",
      "deviceData": {
        temperature: 30.5
      },
      "version": "1.0.0"
    },
    /** 異常応答(datatype = Device) */
    getDeviceData_009: {
      "deviceId": "Sensor A",
      "datatype": "Device",
      "deviceData": {
        temperature: 30.5
      },
      "version": "1.0.0"
    },


    /***********************************
    * getDeviceSpec
    ************************************/
    /** 正常応答 */
    getDeviceSpec_001: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceName": "会議室の温度",
      "specs": {
        "location": "会議室",
        "setupDate": "2021/02/01",
        "updatePeriod": "1",
        "lifeTime": "10"
      },
      "version": "1.0.0"
    },
    /** 異常応答(deviceId = 空文字) */
    getDeviceSpec_002: {
      "deviceId": "",
      "datatype": "ThermoSensor",
      "deviceName": "会議室の温度",
      "specs": {
        "location": "location",
        "setupDate": "setupDate",
        "updatePeriod": "updatePeriod",
        "lifeTime": "lifeTime"
      },
      "version": "version"
    },
    /** 異常応答(datatype = null) */
    getDeviceSpec_003: {
      "deviceId": "Sensor A",
      "datatype": null,
      "deviceName": "会議室の温度",
      "specs": {
        "location": "location",
        "setupDate": "setupDate",
        "updatePeriod": "updatePeriod",
        "lifeTime": "lifeTime"
      },
      "version": "version"
    },
    /** 異常応答(deviceName = undefined) */
    getDeviceSpec_004: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceName": undefined,
      "specs": {
        "location": "location",
        "setupDate": "setupDate",
        "updatePeriod": "updatePeriod",
        "lifeTime": "lifeTime"
      },
      "version": "version"
    },
    /** 正常応答(specs = {空}) */
    getDeviceSpec_005: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceName": "deviceName",
      "specs": {},
      "version": "version"
    },
    /** 異常応答(specs = undefined) */
    getDeviceSpec_006: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceName": "deviceName",
      "specs": {},
      "version": "version"
    },
    /** 異常応答(version = undefined) */
    getDeviceSpec_007: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceName": "deviceName",
      "specs": {
        "location": "location",
        "setupDate": "setupDate",
        "updatePeriod": "updatePeriod",
        "lifeTime": "lifeTime"
      },
      "version": undefined
    },
    /** 準正常応答(specsに想定しない項目) */
    getDeviceSpec_008: {
      "deviceId": "Sensor A",
      "datatype": "ThermoSensor",
      "deviceName": "deviceName",
      "specs": {
        "location": "location",
        "setupDate": "setupDate",
        "updatePeriod": "updatePeriod",
        "lifeTime": "lifeTime",
        "dummy": "dummy"
      },
      "version": "version"
    },
  };

  /**
   * クラウド通信の応答情報
   */
  public cloudResponse: any;

  /**
   * コンストラクタ
   * @param logger ログ出力サービス
   */
  public constructor(logger: LoggerService) {
    this.logger = logger;
    this.converter = new Converter();

    // クラウド通信の応答情報の初期値セット
    this.cloudResponse = {
      isHttpSuccess: true,
      login: this.cloudResponseList.login_001,
      getDeviceList: this.cloudResponseList.getDeviceList_001,
      getDeviceData: this.cloudResponseList.getDeviceData_001,
      getDeviceSpec: this.cloudResponseList.getDeviceSpec_001,
      errorCode: this.cloudResponseList.error_Unauthorized,
    }
  }

  /**
   * ログイン
   * @param user ユーザー情報
   * @returns 成功/失敗(Observableオブジェクト)
   */
  public login(user: User): Observable<boolean> {
    this.logger.debug(`[CloudConnectionMock] login userInfo=${user}`);
    const subject: Subject<boolean> = new Subject<boolean>();

    // 本来の環境と合わせる為、応答を非同期化する
    setTimeout(() => {
      // テスト用のレスポンス情報を設定
      if (this.cloudResponse.isHttpSuccess) {
        // 成功レスポンスの場合
        subject.next(this.cloudResponse.login);
      } else {
        // 失敗レスポンスの場合
        subject.error(this.cloudResponse.errorCode);
      }
    }, this.responseTime);

    return subject.asObservable();
  }

  /**
   * ログアウト
   */
  public logout() {
    this.logger.debug("[CloudConnectionMock] logout");
  }

  /**
   * デバイス一覧取得
   * @returns デバイス一覧
   */
  public getDeviceList(): Observable<DeviceList> {
    this.logger.debug("[CloudConnectionMock] getDeviceList");
    const subject: Subject<DeviceList> = new Subject<DeviceList>();

    // 本来の環境と合わせる為、応答を非同期化する
    setTimeout(() => {
      // テスト用のレスポンス情報を設定
      if (this.cloudResponse.isHttpSuccess) {
        const deviceList: DeviceList =
          this.converter.convertDeviceList(this.cloudResponse.getDeviceList);
        // 成功レスポンスの場合
        subject.next(deviceList);
      } else {
        // 失敗レスポンスの場合
        subject.error(this.cloudResponse.errorCode);
      }
    }, this.responseTime);

    return subject.asObservable();
  }

  /**
   * デバイスデータ取得
   * @param deviceId デバイスID
   * @returns デバイスデータ
   */
  public getDeviceData(deviceId: string): Observable<DeviceData> {
    this.logger.debug(`[CloudConnectionMock] getDeviceData deviceId=${deviceId}`);
    const subject: Subject<DeviceData> = new Subject<DeviceData>();

    // 本来の環境と合わせる為、応答を非同期化する
    setTimeout(() => {
      // テスト用のレスポンス情報を設定
      if (this.cloudResponse.isHttpSuccess) {
        const deviceData =
          this.converter.convertDeviceData(this.cloudResponse.getDeviceData);
        // 成功レスポンスの場合
        subject.next(deviceData);
      } else {
        // 失敗レスポンスの場合
        subject.error(this.cloudResponse.errorCode);
      }
    }, this.responseTime);

    return subject.asObservable();
  }

  /**
   * デバイス詳細情報取得
   * @param deviceId デバイスID
   * @returns デバイス詳細情報
   */
  public getDeviceSpec(deviceId: string): Observable<DeviceSpec> {
    this.logger.debug(`[CloudConnectionMock] getDeviceSpec deviceId=${deviceId}`);
    const subject: Subject<DeviceSpec> = new Subject<DeviceSpec>();

    // 本来の環境と合わせる為、応答を非同期化する
    setTimeout(() => {
      // テスト用のレスポンス情報を設定
      if (this.cloudResponse.isHttpSuccess) {
        const deviceSpec =
          this.converter.convertDeviceSpec(this.cloudResponse.getDeviceSpec);
        // 成功レスポンスの場合
        subject.next(deviceSpec);
      } else {
        // 失敗レスポンスの場合
        subject.error(this.cloudResponse.errorCode);
      }
    }, this.responseTime);

    return subject.asObservable();
  }
   
  private toFail = false;
  private error: any = new Error();

  setError(e: any=null) {
    this.toFail = true;
    this.error = e ?? new Error();
}

  reset() {
      this.toFail = false;
      this.error = new Error();
  }

  getDeviceDataGraphUrl(deviceId: string): Observable<string> {
    return !this.toFail ? of('') : throwError(this.error);
  }

  getNotificationList(condition: EventCondition): Observable<INotification[]> {
    throw new Error('Method not implemented.');
  }
  getNotificationCount(condition: EventCondition): Observable<number> {
    throw new Error('Method not implemented.');
  }
  getNotificationDetail(eventIdentifier: string): Observable<INotification> {
    throw new Error('Method not implemented.');
  }
  postNotificationDetail(notification: INotification): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
}