/** デバイス詳細情報モデル */
export class DeviceSpec {
  /** デバイスID */
  deviceId: string;

  /** データタイプ */
  datatype: string;

  /** デバイス名称 */
  deviceName: string;

  /** デバイス詳細情報 */
  specs: DeviceSpecItem;

  /** バージョン */
  version: string;

  /**
   * デバイス詳細情報モデルのバリデーションチェック
   * @returns true: 正常 false: 異常
   */
  get isValid(): boolean {
    // 必須項目チェック
    return [
      this.deviceId,
      this.datatype,
      this.deviceName,
      this.specs,
      this.version
    ].every(check => check);
  }
}

/** デバイス詳細情報 項目モデル */
export class DeviceSpecItem {
  /** デバイス詳細 */
  [spec: string]: string;
}