/**
 * 通信エラーの列挙型
 * 通信エラーを下記のエラーコードに変換して使用する
 */
export enum ConnectionError {
  /** 認証失敗 */
  Unauthorized = "Unauthorized",
  /** 対象が存在しない */
  NotFound = "NotFound",
  /** 接続タイムアウト */
  Timeout = "Timeout",
  /** 上記以外のエラー */
  Other = "Other"
}
