import { ICloud } from '../services/icloud';
import { Observable, Subject } from 'rxjs';
import { DeviceList } from 'src/app/models/device-list';
import { DeviceData } from 'src/app/models/device-data';
import { DeviceSpec } from 'src/app/models/device-spec';
import { LoggerService } from 'src/app/services/logger-service';
import { ConnectionError } from '../connection/connection-error.enum';
import { ThermoSensor, Tree, TThermoSensor } from '../models/datatype-consts';
import { validateData } from '../models/datatype';
import { catchError, tap } from 'rxjs/operators';

/**
 * デバイスサービス
 */
export class DeviceService {

  /** ログサービス */
  private logger: LoggerService;

  /** クラウド通信クラス */
  private readonly cloud: ICloud;

  /**
   * コンストラクタ
   * @param logger ログサービス
   * @param cloud クラウド通信クラス
   */
  constructor(logger: LoggerService, cloud: ICloud) {
    this.logger = logger;
    this.cloud = cloud;
  }

  /**
   * デバイス一覧取得
   */
  public getDeviceList(): Observable<DeviceList> {
    this.logger.debug(`[DeviceService] getDeviceList`);
    const subject: Subject<DeviceList> = new Subject<DeviceList>();

    // デバイス一覧取得要求 
    const subscription =
      this.cloud.getDeviceList().subscribe((deviceList: DeviceList) => {
        this.logger.info(`[DeviceService] Success deviceList=${JSON.stringify(deviceList)}`);
        // バリデーションチェック
        if (deviceList.isValid) {
          // 正常
          subject.next(deviceList);
        } else {
          // 異常
          subject.error(ConnectionError.Other);
        }
        subscription.unsubscribe();
      }, (error: ConnectionError) => {
        this.logger.error(`[DeviceService] Failure error=${JSON.stringify(error)}`);
        subject.error(error);
        subscription.unsubscribe();
      });

    return subject.asObservable();
  }

  /**
   * 検温センサー情報取得
   * @param deviceId デバイスID
   * @returns 検温センサー情報
   */
  public getThermoData(deviceId: string): Observable<TThermoSensor> {
    this.logger.debug(`[DeviceService] getThermoData deviceId=${deviceId}`);
    const subject: Subject<TThermoSensor> = new Subject<TThermoSensor>();

    // 検温センサー情報の取得要求 
    const subscription =
      this.cloud.getDeviceData(deviceId).subscribe((deviceData: DeviceData) => {
        this.logger.info(`[DeviceService] Success deviceData=${JSON.stringify(deviceData)}`);
        // バリデーションチェック正常の場合
        if (deviceData.isValid) {
          // 型付け対象の検温センサー情報取得
          const thermoData = deviceData.deviceData;
          // データタイプの型付けを行う
          const actual = Tree.Dispatch(deviceData.datatype, thermoData);
          if (validateData(thermoData, actual, ThermoSensor)) {
            // 型付け成功時の場合
            subject.next(thermoData);
          } else {
            // 型付け失敗時の場合
            subject.error(ConnectionError.Other);
          }
        } else {
          // バリデーションチェック異常の場合
          subject.error(ConnectionError.Other);
        }

        subscription.unsubscribe();
      }, (error: ConnectionError) => {
        this.logger.error(`[DeviceService] Failure error=${JSON.stringify(error)}`);
        subject.error(error);
        subscription.unsubscribe();
      });

    return subject.asObservable();
  }

  /**
   * デバイス詳細情報取得
   * @param deviceId デバイスID
   * @returns デバイス詳細情報
   */
  public getDeviceSpec(deviceId: string): Observable<DeviceSpec> {
    this.logger.debug(`[DeviceService] getDeviceSpec deviceId=${deviceId}`);
    const subject: Subject<DeviceSpec> = new Subject<DeviceSpec>();

    // デバイス詳細情報の取得要求 
    const subscription =
      this.cloud.getDeviceSpec(deviceId).subscribe((deviceSpec: DeviceSpec) => {
        this.logger.info(`[DeviceService] Success deviceSpec=${JSON.stringify(deviceSpec)}`);
        // バリデーションチェック
        if (deviceSpec.isValid) {
          // 正常
          subject.next(deviceSpec);
        } else {
          // 異常
          subject.error(ConnectionError.Other);
        }
        
        subscription.unsubscribe();
      }, error => {
        this.logger.error(`[DeviceService] Failure error=${JSON.stringify(error)}`);
        subject.error(error);
        subscription.unsubscribe();
      });

    return subject.asObservable();
  }

  getDeviceDataGraphUrl(deviceId: string): Observable<string> {
    this.logger.info('デバイスデータグラフURL取得')

    return this.cloud.getDeviceDataGraphUrl(deviceId).pipe(
      tap(url => this.logger.info(`デバイスデータグラフURL取得結果 url=${url}`)),
      catchError(e => {
        this.logger.error(`デバイスデータグラフ取得失敗 error=${e}`)
        throw e
      })
    )
  }
}
