
/** 各通信クラスの共通定数 */
export const Const = {
  /** RestApi 接続情報 */
  RestApis: {
    /** デバイストークン登録 */
    registerToken: { URI: '/token' },

    /** デバイス一覧取得 */
    deviceList: { URI: '/devices' },

    /** デバイスデータ取得 */
    deviceData: { URI: (deviceId: string) => `/devices/${deviceId}/deviceData/latest` },

    /** デバイス詳細情報取得 */
    deviceSpec: { URI: (deviceId: string) => `/devices/${deviceId}` },

    deviceDataGraph: { URI: (deviceId: string) => `/devices/${deviceId}/deviceData/graph` },

    /** お知らせ一覧取得 */
    notificationList: { URI: '/events/notification' },

    notificationCount: { URI: '/events/notification/count' },
    
    notificationDetail: { URI: (evetIdentifier: string) => `/events/notification/${encodeURIComponent(evetIdentifier)}` },
  },
  /** HTTP通信情報 */
  HttpOptions: {
    /** タイムアウト値(ミリ秒) */
    timeout: 30 * 1000
  }
}
