/** デバイス一覧モデル */
export class DeviceList {
  /** デバイス一覧 */
  devices: DeviceListItem[];

  /**
   * デバイス一覧モデルのバリデーションチェック
   * @returns true: 正常 false: 異常
   */
  get isValid(): boolean {
    // デバイス一覧が存在しない場合
    if (this.devices.length === 0) {
      return false;
    }
    // 必須項目チェック
    return this.devices.every(d =>
      d.deviceId && d.datatype && d.deviceName && d.version
    );
  }
}

/** デバイス一覧 項目モデル */
export class DeviceListItem {
  /** デバイスID */
  deviceId: string;

  /** データタイプ */
  datatype: string;

  /** デバイス名称 */
  deviceName: string;

  /** バージョン */
  version: string;
}
