import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppProviderService } from './app-provider.service';
import { setAppInjector } from './app-injector';

import {FormsModule} from '@angular/forms';
import { FilterComponent } from './gui/components/filter/filter.component';

@NgModule({
  declarations: [AppComponent, FilterComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: AppProviderService, useClass: AppProviderService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}

// ステータス更新用
export class UpdateStatusModel{
  constructor(
    public status: number,
    public comment: string
  ){}
}
