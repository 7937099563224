import { DatatypeNode } from "./datatype-node";
import { Datatype } from "./datatype";

/**
 * データタイプツリー
 */
export class DatatypeTree {
  /**
   * コンストラクタ
   * @param root ルートノード
   */
  constructor(public readonly root: DatatypeNode) {
  }

  /**
   * データタイプの探索
   * @param typeName データタイプ名
   * @param data デバイスデータ
   * @returns 探索結果
   */
  Dispatch(typeName: string, data: { [key: string]: any }): Datatype {
    const [found, valid, actual] = this.DispatchImpl(typeName, data, this.root);

    if (!found || !valid) {
      // 未発見、型付けチェック失敗のいづれかであればルートを返す
      return this.root.type;
    }

    return actual.type;
  }

  /**
   * データタイプの探索（再帰）
   * @param typeName データタイプ名
   * @param data デバイスデータ
   * @param parent 現在の親ノード
   * @returns （探索成否、検証成否、発見ノード）
   */
  private DispatchImpl(typeName: string,
    data: { [key: string]: any },
    parent: DatatypeNode): [boolean, boolean, DatatypeNode] {
    // 探索対象と一致してれば探索完了
    if (parent.type.name == typeName) {
      // 型付けチェックして終了
      return [true, parent.type.validateData(data), parent];
    }

    for (const child of parent.children) {
      // 子ノードに対して再帰呼び出し（深さ優先探索）
      const result = this.DispatchImpl(typeName, data, child);

      const [found, valid, _] = result;

      if (found && valid) {
        // 発見され型付けチェックも成功ならば右辺値にキャストしてそのまま返す
        return result;
      }
      else if (found && !valid) {
        // 発見されたが型付けチェックで失敗した場合は
        // 親ノードで型付けチェックして返す
        return [true, parent.type.validateData(data), parent];
      }
    }

    // 子ノード内では発見できなかったた場合は未発見かつ型付けチェック失敗で返す
    return [false, false, parent];
  }
}
