import { Injectable } from '@angular/core';
import { ICloud } from 'src/app/services/icloud';
import { CloudConnection } from 'src/app/connection/cloud-connection';
import { CloudConnectionMock } from 'src/app/connection/cloud-connection-mock';
import { DeviceService } from 'src/app/services/device-service';
import { LoginService } from 'src/app/services/login-service';
import { LoggerService } from 'src/app/services/logger-service';
import { environment } from 'src/environments/environment';
import { EventService } from './services/event-service';
@Injectable({
  providedIn: 'root'
})
export class AppProviderService {

  /** クラウド通信 */
  cloud: ICloud;

  /** ログインサービス */
  loginService: LoginService;

  /** デバイスサービス */
  deviceService: DeviceService;
  
  eventService: EventService;

  /** ログサービス */
  logger: LoggerService;

  /**
   * コンストラクタ
   * アプリ起動時に実行される
   * クラウド通信クラス および、各サービスのインスタンスを生成する
   */
  constructor() { 
    this.logger = new LoggerService();

    // ビルドモードに応じてクラウド通信オブジェクト生成
    if (environment.isLocal) {
      this.cloud = new CloudConnectionMock(this.logger);
    } else {
      this.cloud = new CloudConnection(this.logger);
    }

    // 各サービスの初期化
    this.eventService = new EventService(this.logger, this.cloud);
    this.loginService = new LoginService(this.logger, this.cloud, this.eventService);
    this.deviceService = new DeviceService(this.logger, this.cloud);
  }
}
