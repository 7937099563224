import { BasePage } from './gui/pages/base-page';
import { Component } from '@angular/core';
import { MessageConst } from 'src/app/gui/pages/message-const';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BasePage {
  /**
   * アプリケーションメニュー
   * ハンバーガーメニューに表示される項目一覧
   */
  public appPages = [
    {
      title: 'デバイス一覧',
      url: '/device-list',
      icon: 'desktop-outline'
    },

    {
      title: 'お知らせ一覧',
      url: '/notice-list',
      icon: 'alert-circle-outline',
      badge: this.eventService.getUnreadNotificationCounter(60 * 1000) // 1分おきに未読数更新
    },

  ];

  /**
   * コンストラクタ
   */
  constructor() {
    super();
  }

  /**
   * ログアウト
   */
  public async onLogout() {
    const confirm =
      await this.showConfirmDialog(MessageConst.confirmLogout);
    if (confirm) {
      this.loginService.logout();
      this.navController.navigateRoot('/');
    }
  }
}
