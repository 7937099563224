import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { AsyncSubject, Observable, of, Subject } from 'rxjs';
import { ICloud } from 'src/app/services/icloud';
import { HttpBase } from './http-base';
import { Http } from './http';
import { User } from 'src/app/models/user';
import { LoggerService } from 'src/app/services/logger-service'
import { Const } from 'src/app/connection/const';
import { HttpHeaders } from '@angular/common/http';
import { DeviceList } from 'src/app/models/device-list';
import { DeviceData } from 'src/app/models/device-data';
import { DeviceSpec } from 'src/app/models/device-spec';
import { Converter } from './converter';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { EventCondition, INotification } from '../models/notification';

/**
 * クラウド通信クラス
 */
export class CloudConnection implements ICloud {

  /** HTTP通信モジュール */
  private http: HttpBase;

  /** ユーザープール */
  private userPool: CognitoUserPool;

  /** ユーザープールデータ */
  private poolData: any;

  /** ログ出力サービス */
  private logger: LoggerService;

  /** HTTP通信情報の変換クラス */
  private converter: Converter;
  
  /** Cognitoのユーザーセッション（有効期限一時間、getSessionの呼び出しのたびにrefreshされる） */
  private cognitoSession$: AsyncSubject<CognitoUserSession>;

  /**
   * コンストラクタ
   * @param logger ログ出力サービス
   */
  public constructor(logger: LoggerService) {
    this.logger = logger;
    this.poolData = {
      UserPoolId: environment.AWS.UserPoolId,
      ClientId: environment.AWS.ClientId,
      Paranoia: 7
    };
    this.userPool = new CognitoUserPool(this.poolData);
    this.http = new Http();
    this.converter = new Converter();
  }

  /**
   * ログイン
   * @param user ユーザー情報
   * @returns 成功/失敗(Observableオブジェクト)
   */
  public login(user: User): Observable<boolean> {
    this.logger.debug("[CloudConnection] login");
    const subject: Subject<boolean> = new Subject<boolean>();
    const userData = { Username: user.userId, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    const authenticationData = { Username: user.userId, Password: user.password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    // Cognitoセッション用のsubjectを初期化
    this.cognitoSession$?.unsubscribe();
    this.cognitoSession$ = new AsyncSubject<CognitoUserSession>();

    // ログインに成功すると、Cognito UserPool tokenが取得でき、ローカルストレージに保存される
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: CognitoUserSession) => {
        this.logger.debug(`[CloudConnection] login onSuccess result=${JSON.stringify(result)}`);
        subject.next(true);
      },
      onFailure: (err) => {
        this.logger.error(`[CloudConnection] login onFailure err=${JSON.stringify(err)}`);
        // AWS Cognitoのエラー情報をエラーコードに変換
        subject.error(this.converter.convertCognitoError(err));
      }
    });

    return subject.asObservable();
  }

  /**
   * ログアウト
   */
  public logout() {
    this.logger.debug("[CloudConnection] logout");
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
  }

  /**
   * デバイス一覧取得
   * @returns デバイス一覧
   */
  public getDeviceList(): Observable<DeviceList> {
    this.logger.debug("[CloudConnection] getDeviceList");
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.deviceList.URI}`;

    return this.createHeader().pipe(
      mergeMap(header => this.http.get(uri, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => this.converter.convertDeviceList(res)),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  }

  /**
   * デバイスデータ取得
   * @param deviceId デバイスID
   * @returns デバイスデータ
   */
  public getDeviceData(deviceId: string): Observable<DeviceData> {
    this.logger.debug(`[CloudConnection] getDeviceData deviceId=${deviceId}`);
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.deviceData.URI(deviceId)}`;

    return this.createHeader().pipe(
      mergeMap(header => this.http.get(uri, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => this.converter.convertDeviceData(res)),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  }

  /**
   * デバイス詳細情報取得
   * @param deviceId デバイスID
   * @returns デバイス詳細情報
   */
  public getDeviceSpec(deviceId: string): Observable<DeviceSpec> {
    this.logger.debug(`[CloudConnection] getDeviceSpec deviceId=${deviceId}`);
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.deviceSpec.URI(deviceId)}`;

    return this.createHeader().pipe(
      mergeMap(header => this.http.get(uri, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => this.converter.convertDeviceSpec(res)),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  }

  public getDeviceDataGraphUrl(deviceId: string): Observable<string> {
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.deviceDataGraph.URI(deviceId)}`;

    return this.createHeader().pipe(
      mergeMap(header => this.http.get(uri, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => this.converter.convertDeviceDataGraph(res)),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  } 

  public getNotificationList(condition: EventCondition): Observable<INotification[]> {
    this.logger.debug('[CloudConnection] getNotificationList');
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.notificationList.URI}`;

    const body = this.converter.convertEventConditionToReq(condition);

    return this.createHeader().pipe(
      mergeMap(header => this.http.post(uri, body, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => this.converter.convertNotificationList(res)),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  }

  public getNotificationCount(condition: EventCondition): Observable<number> {
    this.logger.debug('[CloudConnection] getNotificationCount');
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.notificationCount.URI}`;

    const body = this.converter.convertEventConditionToReq(condition);

    return this.createHeader().pipe(
      mergeMap(header => this.http.post(uri, body, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => this.converter.convertNotificationCount(res)),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  }

  public getNotificationDetail(eventIdentifier: string): Observable<INotification> {
    this.logger.debug('[CloudConnection] getNotificationDetail');
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.notificationDetail.URI(eventIdentifier)}`;

    return this.createHeader().pipe(
      mergeMap(header => this.http.get(uri, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => this.converter.convertNotification(res)),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  }

  public postNotificationDetail(notification: INotification): Observable<boolean> {
    this.logger.debug('[CloudConnection] postNotificationDetail');
    const uri = `https://${environment.AWS.host}/${environment.AWS.stage}${Const.RestApis.notificationDetail.URI(notification.eventIdentifier)}`;

    const body = this.converter.convertNotificationToReq(notification);

    return this.createHeader().pipe(
      mergeMap(header => this.http.post(uri, body, { headers: header })),
      tap(res => this.logger.info(`[CloudConnection] Success res=${JSON.stringify(res)}`)),
      map(res => res.ok),
      catchError(error => {
        this.logger.error(`[CloudConnection] Failure error=${JSON.stringify(error)}`);
        throw this.converter.convertHttpError(error);
      })
    );
  }

  /**
   * HTTPヘッダーを生成
   * @returns HTTPヘッダー
   */
  private createHeader(): Observable<HttpHeaders> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');

    return this.getIdToken().pipe(
      // undefinedの場合は空文字にしてセット
      // 空文字でアクセスすることで認証エラーに倒せる
      map(token => headers.set('Authorization', token ?? ''))
    );
  }

  /**
   * IDトークン取得処理
   * @returns 認証情報
   * @description 認証情報、セッション情報、IDトークンが存在しない場合は undefined を返す
   */
  private getIdToken(): Observable<string> {
    return this.getSession().pipe(
      map(session => session?.getIdToken()?.getJwtToken())
    );
  }

  /**
   * 認証されているか
   * @returns セッション or エラー or undefined
   */
  private getSession(): Observable<CognitoUserSession> {
    if (!this.cognitoSession$) {
      this.logger.error(`[CloudConnection] not logged in`);
      return of(undefined);
    }

    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser === null) {
      this.logger.error(`[CloudConnection] getSession error cognitoUser null`);
      return of(undefined);
    }

    // getSessionの呼び出しのたびにrefresh（有効期限が伸びる）される
    // 有効期限が切れたときにgetSessionが新しくセッションを発行してしまうため
    // 1回目のgetSession結果を使いまわして、有効期限切れの際に403が発生するようにする
    cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
      if (err) {
        this.logger.error(`[CloudConnection] getSession error err=${err}`);
        this.cognitoSession$.error(err);
      } else {
        this.logger.debug('[CloudConnection] getSession success');
        this.cognitoSession$.next(session);
        this.cognitoSession$.complete(); // 1回目の呼び出しで完了する、1回目の結果を使いまわす
      }
    });

    return this.cognitoSession$.asObservable();
  }
}
