import { Injector } from '@angular/core';
export let AppInjector: Injector;

/**
 * インジェクター設定
 * @param injector インジェクター
 */
export function setAppInjector(injector: Injector) {
  if (AppInjector) {
    console.warn('Programming warn: AppInjector was already set');
  }
  AppInjector = injector;
}
