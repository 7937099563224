/** デバイスデータモデル */
export class DeviceData {
  /** デバイスID */
  deviceId: string;

  /** データタイプ */
  datatype: string;

  /** デバイスデータ */
  deviceData: {
    [key: string]: any
  };

  /** バージョン */
  version: string;

  /**
   * デバイスデータモデルのバリデーションチェック
   * @returns true: 正常 false: 異常
   */
  get isValid(): boolean {
    // 必須項目チェック
    return [
      this.deviceId,
      this.datatype,
      this.deviceData,
      this.version
    ].every(check => check);
  }
}