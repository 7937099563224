import * as log from 'loglevel';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /** 
   * ローカルモードフラグ
   * true ：単体試験モード(クラウド通信モックを使用。オフラインモードとなる。)
   * false: 本番モード
   */
  isLocal: false,
  
  // /** AWS設定 */
  // AWS: {
  //   region: 'ap-northeast-1',
  //   host: 'yieo5539ji.execute-api.ap-northeast-1.amazonaws.com',
  //   stage: 'dev-thermo',
  //   certKeyPath: 'src/certs/1c483b59aa-private.pem.key',
  //   certPath: 'src/certs/1c483b59aa-certificate.pem.crt',
  //   caPath: 'src/certs/root-CA.crt',
  //   port: 443,
  //   identityPoolId: 'ap-northeast-1:11ba09b3-9b9f-4fdd-bd98-18210745a1e7',
  //   UserPoolId: 'ap-northeast-1_VAUIgjz9G',
  //   ClientId: '3varao7a6b066v83r0km0iss37'
  // },
  /** AWS設定 */
  AWS: {
    region: 'ap-northeast-1',
    host: 'cy5m8yjvcg.execute-api.ap-northeast-1.amazonaws.com',
    stage: 'dev',
    // certKeyPath: 'src/certs/1c483b59aa-private.pem.key',
    // certPath: 'src/certs/1c483b59aa-certificate.pem.crt',
    // caPath: 'src/certs/root-CA.crt',
    port: 443,
    // identityPoolId: 'ap-northeast-1:376664669605',// 未使用
    UserPoolId: 'ap-northeast-1_werkVP3Qm',
    ClientId: '5fcm4ptfhsd4m0d95k7thaul54'
  },

  /** コンソールに出力するログレベル */
  consoleLogLevel: log.levels.TRACE,
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
