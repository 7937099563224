export enum EventType {
    Notification = 'notification'
}

export interface INotification {
    eventType: EventType;
    eventIdentifier: string;
    deviceID: string;
    occurred: number;
    statuses: {
        read : boolean,
        handled : number,
        level : number,
        comment : string,
        subject: string,
        body: string,
        body_html: string
    };
}

export class EventCondition {
    constructor(
        public deviceIdList: string[],
        public occurredStart: Date | null,
        public occurredEnd: Date | null,
        public statuses: {[key: string]: any},
    ) {
        
    }
}
